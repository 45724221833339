@import 'mixins'
*, ::after, ::before
    box-sizing: border-box
html
    font-family: sans-serif
    line-height: 1.15
    -webkit-text-size-adjust: 100%
    -webkit-tap-highlight-color: transparent
body
    margin: 0
    font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
    font-size: 1rem
    font-weight: 400
    line-height: 1.5
    color: #858796
    text-align: left
    background-color: #fff
    height: 100%
button, input
    overflow: visible
input
    &[type=checkbox], &[type=radio]
        box-sizing: border-box
        padding: 0
.input
    &-group-append
        margin-left: -1px
    &-group-append, &-group-prepend
        display: flex
.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text
    border-top-left-radius: 0
    border-bottom-left-radius: 0
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl
    padding-left: 1.5rem
    padding-right: 1.5rem
.mr, .mx
    &-auto
        margin-right: auto!important
    &-2
        margin-right: 0.5rem!important
.ml
    &-auto
        margin-left: auto!important
.text-gray-600
    color: #858796!important
form
    &.user
        .btn-user
            font-size: .8rem
            border-radius: 10rem
            padding: 0.75rem 1rem
        .custom-checkbox
            &.small
                label
                    line-height: 1.5rem
        .form-control-user
            font-size: .8rem
            border-radius: 10rem
            padding: 1.5rem 1rem
.bg-gradient-primary
    background-color: $colorPrincipal
    background-image: linear-gradient(180deg,$colorPrincipal 10%,#224abe 100%)
    background-size: cover
.accordion
    overflow-anchor: none
.btn
    &-primary
        background-color: $colorPrincipal
        border-color: $colorPrincipal
    &-block
        display: block
        width: 100%
.form-group
    margin-bottom: 1rem
.o-hidden
    overflow: hidden!important
.custom
    &-checkbox
        .custom-control-label
            &::before
                border-radius: 0.35rem
    &-control
        position: relative
        z-index: 1
        display: block
        min-height: 1.5rem
        padding-left: 1.5rem
        -webkit-print-color-adjust: exact
        color-adjust: exact
.custom-control-label::before, .custom-file-label, .custom-select
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
hr
    margin-top: 1rem
    margin-bottom: 1rem
    border: 0
    border-top: 1px solid rgba(0,0,0,.1)
.badge
    display: inline-block
    padding: 0.25em 0.4em
    font-size: 75%
    font-weight: 700
    line-height: 1
    text-align: center
    white-space: nowrap
    vertical-align: baseline
    border-radius: 0.35rem
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
    &-danger
        color: #fff
        background-color: #e74a3b
// AppHeader
.navbar
    padding: 0.5rem 1rem
.topbar
  height: 4.375rem
  .topbar-divider
    width: 0
    border-right: 1px solid #e3e6f0
    height: calc(4.375rem - 2rem)
    margin: auto 1rem
  #sidebarToggleTop
    height: 2.5rem
    width: 2.5rem
  .navbar-search
    width: 25rem
    input
        font-size: .85rem
        height: auto
  .dropdown
    position: static
    .dropdown-menu
      width: calc(100% - 1.5rem)
      right: 0.75rem
  .nav-item
    .nav-link
      height: 4.375rem
      display: flex
      align-items: center
      padding: 0 0.75rem
    button
      border: 0
  &.navbar-light
    .navbar-nav
        .nav-item
            .nav-link
                color: #d1d3e2
// AppSidebar
.sidebar
    background-color: $colorPrincipal
    width: 6.5rem
    min-height: 100vh
    .sidebar-heading
        text-align: center
        padding: 0 1rem
        font-weight: 800
        font-size: .65rem
    .nav-item
        position: relative
        .collapse
            -webkit-animation-name: growIn
            animation-name: growIn
            -webkit-animation-duration: .2s
            animation-duration: .2s
            -webkit-animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1)
            animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1)
            position: absolute
            left: calc(6.5rem + 1.5rem / 2)
            z-index: 1
            top: 2px
            .collapse-inner
                border-radius: 0.35rem
                box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 15%)
        .collapse, .collapsing
            .collapse-inner
                padding: 0.5rem 0
                min-width: 10rem
                font-size: .85rem
                margin: 0 0 1rem 0
                .collapse-header
                    margin: 0
                    white-space: nowrap
                    padding: 0.5rem 1.5rem
                    text-transform: uppercase
                    font-weight: 800
                    font-size: .65rem
                    color: #b7b9cc
                .collapse-item
                    padding: 0.5rem 1rem
                    margin: 0 0.5rem
                    display: block
                    color: #3a3b45
                    text-decoration: none
                    border-radius: 0.35rem
                    white-space: nowrap
        .nav-link
            text-align: center
            padding: 0.75rem 1rem
            width: 6.5rem
            color: rgba(255,255,255,.8)
            span
                font-size: .65rem
                display: block
        &.active
            .nav-link
                font-weight: 700
    hr
        &.sidebar-divider
            margin: 0 1rem 1rem
    &-dark
        .sidebar-brand
            color: #fff
        .sidebar-heading
            color: rgba(255,255,255,.4)
        .nav-item
            .nav-link
                color: rgba(255,255,255,.8)
                &[data-toggle=collapse]
                    &::after
                        color: rgba(255,255,255,.5)
                i
                    color: rgba(255,255,255,.3)
            &.active, &:hover
                .nav-link
                    color: #fff
                    i
                        color: #fff
        hr
            &.sidebar-divider
                border-top: 1px solid rgba(255,255,255,.15)
    .sidebar-brand
        height: 4.375rem
        text-decoration: none
        font-size: 1rem
        font-weight: 800
        padding: 1.5rem 1rem
        text-align: center
        text-transform: uppercase
        letter-spacing: .05rem
        z-index: 1
        background-color: #fff
.sidebar, .topbar
    .nav-item
        .nav-link
            position: relative
            .badge-counter
                position: absolute
                transform: scale(.7)
                transform-origin: top right
                right: 0.25rem
                margin-top: -0.25rem
// AppFooter
footer
    &.sticky-footer
        padding: 2rem 0
        flex-shrink: 0
        .copyright
            line-height: 1
            font-size: .8rem
// Tabla
.tablaList
    .data-table-extensions
        > .data-table-extensions-filter
            > .filter-text
                border-color: #ccc
// Spinner
.spinner-wrapper
    position: fixed
    z-index: 999
    width: 100%
    height: 100%
    left: 0
    background-color: rgba(255, 255, 255, 0.7)
    @include flexbox()
    @include align-items(center)
    @include justify-content(center)
.loader
  color: #4e73df
  font-size: 90px
  text-indent: -9999em
  overflow: hidden
  width: 1em
  height: 1em
  border-radius: 50%
  margin: 72px auto
  position: relative
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease
  animation: load6 1.7s infinite ease, round 1.7s infinite ease
// Formulario
.campoFormulario
  width: 96%
  margin: 2% !important
// 404
.error
    color: #5a5c69
    font-size: 7rem
    position: relative
    line-height: 1
    width: 12.5rem
    &:before
        content: attr(data-text)
        position: absolute
        left: -2px
        text-shadow: 1px 0 #4e73df
        top: 0
        color: #5a5c69
        background: #f8f9fc
        overflow: hidden
        clip: rect(0,900px,0,0)
        animation: noise-anim-2 3s infinite linear alternate-reverse
    &:after
        content: attr(data-text)
        position: absolute
        left: 2px
        text-shadow: -1px 0 #e74a3b
        top: 0
        color: #5a5c69
        background: #f8f9fc
        overflow: hidden
        clip: rect(0,900px,0,0)
        animation: noise-anim 2s infinite linear alternate-reverse
@-webkit-keyframes noise-anim
  0%
    clip: rect(49px, 9999px, 40px, 0)

  5%
    clip: rect(75px, 9999px, 72px, 0)

  10%
    clip: rect(97px, 9999px, 93px, 0)

  15%
    clip: rect(15px, 9999px, 9px, 0)

  20%
    clip: rect(14px, 9999px, 92px, 0)

  25%
    clip: rect(18px, 9999px, 94px, 0)

  30%
    clip: rect(17px, 9999px, 20px, 0)

  35%
    clip: rect(71px, 9999px, 59px, 0)

  40%
    clip: rect(42px, 9999px, 84px, 0)

  45%
    clip: rect(56px, 9999px, 25px, 0)

  50%
    clip: rect(46px, 9999px, 14px, 0)

  55%
    clip: rect(47px, 9999px, 1px, 0)

  60%
    clip: rect(64px, 9999px, 58px, 0)

  65%
    clip: rect(89px, 9999px, 92px, 0)

  70%
    clip: rect(56px, 9999px, 39px, 0)

  75%
    clip: rect(80px, 9999px, 71px, 0)

  80%
    clip: rect(8px, 9999px, 13px, 0)

  85%
    clip: rect(66px, 9999px, 68px, 0)

  90%
    clip: rect(68px, 9999px, 4px, 0)

  95%
    clip: rect(56px, 9999px, 14px, 0)

  100%
    clip: rect(28px, 9999px, 53px, 0)
@keyframes noise-anim
  0%
    clip: rect(49px, 9999px, 40px, 0)

  5%
    clip: rect(75px, 9999px, 72px, 0)

  10%
    clip: rect(97px, 9999px, 93px, 0)

  15%
    clip: rect(15px, 9999px, 9px, 0)

  20%
    clip: rect(14px, 9999px, 92px, 0)

  25%
    clip: rect(18px, 9999px, 94px, 0)

  30%
    clip: rect(17px, 9999px, 20px, 0)

  35%
    clip: rect(71px, 9999px, 59px, 0)

  40%
    clip: rect(42px, 9999px, 84px, 0)

  45%
    clip: rect(56px, 9999px, 25px, 0)

  50%
    clip: rect(46px, 9999px, 14px, 0)

  55%
    clip: rect(47px, 9999px, 1px, 0)

  60%
    clip: rect(64px, 9999px, 58px, 0)

  65%
    clip: rect(89px, 9999px, 92px, 0)

  70%
    clip: rect(56px, 9999px, 39px, 0)

  75%
    clip: rect(80px, 9999px, 71px, 0)

  80%
    clip: rect(8px, 9999px, 13px, 0)

  85%
    clip: rect(66px, 9999px, 68px, 0)

  90%
    clip: rect(68px, 9999px, 4px, 0)

  95%
    clip: rect(56px, 9999px, 14px, 0)

  100%
    clip: rect(28px, 9999px, 53px, 0)
.error
  &:after
    content: attr(data-text)
    position: absolute
    left: 2px
    text-shadow: -1px 0 #e74a3b
    top: 0
    color: #5a5c69
    background: #f8f9fc
    overflow: hidden
    clip: rect(0, 900px, 0, 0)
    animation: noise-anim 2s infinite linear alternate-reverse

  &:before
    content: attr(data-text)
    position: absolute
    left: -2px
    text-shadow: 1px 0 #4e73df
    top: 0
    color: #5a5c69
    background: #f8f9fc
    overflow: hidden
    clip: rect(0, 900px, 0, 0)
    animation: noise-anim-2 3s infinite linear alternate-reverse
@-webkit-keyframes noise-anim-2
  0%
    clip: rect(16px, 9999px, 10px, 0)

  5%
    clip: rect(22px, 9999px, 29px, 0)

  10%
    clip: rect(6px, 9999px, 68px, 0)

  15%
    clip: rect(85px, 9999px, 95px, 0)

  20%
    clip: rect(65px, 9999px, 91px, 0)

  25%
    clip: rect(93px, 9999px, 68px, 0)

  30%
    clip: rect(10px, 9999px, 27px, 0)

  35%
    clip: rect(37px, 9999px, 25px, 0)

  40%
    clip: rect(12px, 9999px, 23px, 0)

  45%
    clip: rect(40px, 9999px, 18px, 0)

  50%
    clip: rect(19px, 9999px, 71px, 0)

  55%
    clip: rect(2px, 9999px, 35px, 0)

  60%
    clip: rect(16px, 9999px, 69px, 0)

  65%
    clip: rect(8px, 9999px, 65px, 0)

  70%
    clip: rect(30px, 9999px, 57px, 0)

  75%
    clip: rect(14px, 9999px, 4px, 0)

  80%
    clip: rect(39px, 9999px, 30px, 0)

  85%
    clip: rect(22px, 9999px, 35px, 0)

  90%
    clip: rect(58px, 9999px, 71px, 0)

  95%
    clip: rect(34px, 9999px, 90px, 0)

  100%
    clip: rect(67px, 9999px, 68px, 0)
@keyframes noise-anim-2
  0%
    clip: rect(16px, 9999px, 10px, 0)

  5%
    clip: rect(22px, 9999px, 29px, 0)

  10%
    clip: rect(6px, 9999px, 68px, 0)

  15%
    clip: rect(85px, 9999px, 95px, 0)

  20%
    clip: rect(65px, 9999px, 91px, 0)

  25%
    clip: rect(93px, 9999px, 68px, 0)

  30%
    clip: rect(10px, 9999px, 27px, 0)

  35%
    clip: rect(37px, 9999px, 25px, 0)

  40%
    clip: rect(12px, 9999px, 23px, 0)

  45%
    clip: rect(40px, 9999px, 18px, 0)

  50%
    clip: rect(19px, 9999px, 71px, 0)

  55%
    clip: rect(2px, 9999px, 35px, 0)

  60%
    clip: rect(16px, 9999px, 69px, 0)

  65%
    clip: rect(8px, 9999px, 65px, 0)

  70%
    clip: rect(30px, 9999px, 57px, 0)

  75%
    clip: rect(14px, 9999px, 4px, 0)

  80%
    clip: rect(39px, 9999px, 30px, 0)

  85%
    clip: rect(22px, 9999px, 35px, 0)

  90%
    clip: rect(58px, 9999px, 71px, 0)

  95%
    clip: rect(34px, 9999px, 90px, 0)

  100%
    clip: rect(67px, 9999px, 68px, 0)
@-webkit-keyframes load6
    0%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
    5%, 95%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
    10%, 59%
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em
    20%
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em
    38%
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em
    100%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
@keyframes load6
    0%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
    5%, 95%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
    10%, 59%
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em
    20%
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em
    38%
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em
    100%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em
@-webkit-keyframes round
    0%
        -webkit-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        transform: rotate(360deg)
@keyframes round
    0%
        -webkit-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        transform: rotate(360deg)
@media (min-width: 768px)
  .campoFormulario
    width: 46%
    .sidebar
        width: 14rem!important
        .sidebar-heading
            text-align: left
        .nav-item
            .collapse, .collapsing
                margin: 0 1rem
            .collapse
                position: relative
                left: 0
                z-index: 1
                top: 0
                -webkit-animation: none
                animation: none
                .collapse-inner
                    border-radius: 0
                    box-shadow: none
            .nav-link
                display: block
                width: 100%
                text-align: left
                padding: 1rem
                width: 14rem
                &[data-toggle=collapse]
                    &::after
                        width: 1rem
                        text-align: center
                        float: right
                        vertical-align: 0
                        border: 0
                        font-weight: 900
                        content: '\f107'
                        font-family: 'Font Awesome 5 Free'
                svg
                    font-size: .85rem
                    margin-right: 0.25rem
                span
                    font-size: .85rem
                    display: inline
@media (min-width: 576px)
    .form-inline
        .custom-select, .input-group
            width: auto
        .form-control
            display: inline-block
            width: auto
            vertical-align: middle
    .topbar
        .dropdown
            position: relative
            .dropdown-menu
                width: auto
                right: 0